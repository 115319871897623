@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --destructive-foreground: 0 0% 100%;
    --secondary-foreground: 0 0% 100%;
    --popover-foreground: 0 0% 0%;
    --primary-foreground: 0 0% 0%;
    --accent-foreground: 0 0% 0%;
    --muted-foreground: 0 0% 20%;
    --card-foreground: 0 0% 0%;
    --destructive: 0 100% 28%;
    --background: 60 100% 97%;
    --foreground: 0 0% 0%;
    --secondary: 123 37% 44%;
    --popover: 0 0% 100%;
    --primary: 51 91% 55%;
    --border: 0 0% 59%;
    --accent: 213 92% 85%;
    --muted: 0 0% 90%;
    --input: 0 0% 65%;
    --card: 0 0% 100%;
    --ring: 51 91% 55%;
    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background font-body text-foreground;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-heading;
  }
}
